import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { makeStyles, CircularProgress, TextField } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos'
import { navigate } from 'gatsby'


import { getUser } from '../services/user.service'
import { AppUser } from '../models/app_user'
import { getUserId } from '../utils/local_storage/login.storage'
import { MessageContext } from '../contexts/message.store'


const useStyles = makeStyles((theme) => ({
  progressInvisible: {
    visibility: 'hidden',
  },
  componentWrapper: {
    display: 'flex',
    width: '100%',
  },
  detailWrapper: {
    width: '100%',
  },
  backArrow: {
    marginTop: '5px',
    marginRight: theme.spacing(1),
    cursor: 'pointer',
  },
  fieldWrappers: {
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
  },
  field: {
    marginBottom: '20px',
  },
}))
export default function AccountComponent(): ReactElement {
  const classes = useStyles()
  const [, messageDispatch] = useContext(MessageContext)
  const [user, setUser] = useState<AppUser>()
  const getUserFromApi = async (): Promise<void> => {
    try {
      const userId = getUserId()
      const apiUser = await getUser(userId)
      setUser(apiUser)
    } catch (err) {
      messageDispatch({ type: 'ERROR', message: err.status })
    }
  }
  useEffect(() => {
    getUserFromApi()
  }, [])

  const goBack = (): void => {
    navigate('/')
  }

  const detail = (): ReactElement => {
    return (
      <div className={classes.detailWrapper}>
        <section className={classes.fieldWrappers}>
          <TextField
            disabled
            className={classes.field}
            label="Name"
            value={user.name}
          />
          <TextField
            label="Email"
            className={classes.field}
            disabled
            value={user.email}
          />
        </section>
      </div>
    )
  }

  return (
    <div className={classes.componentWrapper}>
      <ArrowBackIcon className={classes.backArrow} onClick={goBack} />
      {user ? detail() : (
        <CircularProgress />
      )}
    </div>
  )
}
